import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const SectionContainer = styled.section`
  background-size: cover;
  background-color: white;
  padding-bottom: 2rem;
  margin: 1rem;
  display: grid;
  justify-content: center;
  align-items: center;

  .box {
    max-width: 20rem;
    width: 100%;
  }
  @media screen and (min-width: 620px) {
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
    align-items: center;
    gap: 2rem;
    .box {
      grid-column: 2/3;
      max-width: 30rem;
    }
  }
`
const Button = styled(Link)`
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  border: 2px solid #efc11a;
  &:hover {
    cursor: pointer;
    background-color: #efc11a;
  }
  text-decoration: none;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-row: 1/2;
  grid-column: 1/2;
  @media screen and (min-width: 620px) {
    grid-column: 2/3;
  }
`

const TextContainer = styled.div`
  line-height: 170%;
  /* font-size: 1.5rem; */
  max-width: 20rem;
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  span {
    color: rgba(45, 250, 243);
  }
  @media screen and (min-width: 620px) {
    line-height: 250%;
    margin-left: 3rem;
    margin-right: 0.75rem;
    max-width: 30rem;
    grid-row: 1/2;
    .intro {
      font-size: 2.5rem;
    }
  }
`

export const Hero = ({ title, subtitle, image }) => {
  return (
    <SectionContainer>
      <ImageContainer>
        <div className="box">
          <img width="100%" src={image} alt="Jomi" />
        </div>
      </ImageContainer>
      <TextContainer>
        <h3 className="intro">{title}</h3>
        <p>{subtitle}</p>
        <Button to="/about">Learn More</Button>
      </TextContainer>
    </SectionContainer>
  )
}
