import React from "react"
import styled from "styled-components"

const StyledSection = styled.section`
  background-color: #efc11a;
  display: flex;
  .list {
    column-count: 2;
    list-style: none;
    font-size: 1rem;
    padding: 0;
  }
  .list li {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    font-size: 1.5rem;
  }
  @media screen and (min-width: 600px) {
    .list {
      column-count: 4;
    }
  } ;
`

export const Services = ({ services }) => {
  return (
    <StyledSection>
      <ul className="list">
        {services.map(service => (
          <li key={service.id}>{service.service}</li>
        ))}
      </ul>
    </StyledSection>
  )
}
