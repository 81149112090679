import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Hero } from "../components/home/Hero"
import { Services } from "../components/home/Services"
import { Messenger } from "../components/Messenger"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`
  const hero = data.strapiHomePage.hero
  return (
    <Layout location={location} title={siteTitle}>
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image.url}
      />
      <Services services={data.strapiHomePage.services} />
      <SEO title="Home" />
      <Messenger />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
    strapiHomePage {
      hero {
        image {
          url
        }
        subtitle
        title
      }
      services {
        service
        id
      }
    }
  }
`
